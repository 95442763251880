

.c2screen-meta {
    border: 14px solid var(--c2-bc);
    border-color: var(--bc);
    border-radius: 5px;
    overflow: hidden;
    background-color: var(--c2-bgc);
    padding: 8px;
}

.c2screen-meta h5 {
    font-family: var(--c2-number-font);
    color: var(--c2-fc);
}
.c2screen-meta .screen-title {
    color: var(--c2-fc-dark);
}

.c2screen {
    background-color: var(--c2-bgc);
    text-align: center;
    border: 2px solid black;
    border-radius: 5px;
    overflow: hidden;
    margin-top: 4px;
}

.c2screen .big, .c2screen .medium, 
.c2screen .small {
    padding: 6px 0px ;
}

.c2screen .big {
    font-size: 30px;
    font-weight: 600;
    letter-spacing: 0px;
}

.c2screen .medium {
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0px;
}

.c2screen .small {
    display: inline;
    font-size: 14px;
    font-weight: 600;
    margin-left: 2px;
    letter-spacing: 0px;
}


.c2screen-meta .close-button {
    background-color: var(--c2-bgc);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: black;
    border-radius: 2px;
    border: 1px solid black;
    height: 20px; width: 25px;
}
.c2screen-meta .close-button:hover {
    filter: brightness(75%);
}