@import url(https://cdn.jsdelivr.net/npm/bootstrap-icons@1.4.0/font/bootstrap-icons.css);
@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@200;300;400;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Mulish:wght@200;300;400;500;600;700;800&display=swap);

/* fonts */ /* duolingoesque */

/* test fonts */

/*global config & reset*/
* {
  margin: 0px 0px;
  padding: 0px 0px;
  
  font-family: 'Nunito Sans', sans-serif;
}

body {
  margin: 0px 0px;
  background-color: rgb(244, 246, 248);
  background-color: var(--bgc);
  color: rgb(60,64,67);
  color: var(--color);
}
button {
  outline: none;
  cursor: pointer;
  font-weight: 600;
}
button:disabled,
button:disabled:hover {
  -webkit-filter: brightness(60%);
          filter: brightness(60%);
  cursor: not-allowed;
}
h1, h2, h3, h4, h5, h6, .h7 {
  font-weight: 400;
  color: rgb(10, 12, 14);
  color: var(--color-header);

}
h4 {
  font-size: 16px;
}
h3 {
  font-size: 19px;
}
h5 {
  font-size: 13px;
}
h6 {
  color: rgba(135,138,140,255);
  color: var(--color-secondary);
  font-size: 12px;
}
.h7 {
  font-size: 10px;
}
p, td, th {
  font-weight: 400;
  font-size: 14px;
}
th {
  font-size: 14px;
}
::-webkit-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgba(135,138,140,255);
  color: var(--color-secondary);
  opacity: 1; /* Firefox */
}
:-ms-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgba(135,138,140,255);
  color: var(--color-secondary);
  opacity: 1; /* Firefox */
}
::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgba(135,138,140,255);
  color: var(--color-secondary);
  opacity: 1; /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
* ::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
* {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

html {
  scroll-behavior: smooth;
}

:root {
  --tint-color: var(--color-twitter-blue);
  --tint-color-translucent: var(--color-translucent-twitter-blue);

  --font-family: 'Nunito Sans', sans-serif;


  /* dark mode */
  --bgc-d: black;
  --bgc-light-d: rgb(21,24,28);     /* twitter */
  --bgc-semilight-d: rgb(15, 17, 19);
  --bgc-hover-d: rgb(249,252,255, 0.08);
  --bgc-nav-d: var(--bgc-light-d); 
  --bgc-settings-d: black;    
  --bgc-input-d: rgb(38, 41, 45);  
  --bc-d: rgba(48,54,58,255);         /* robinhood */
  --bc-chart-d: var(--bgc-hover-d);
  --color-d: rgb(205,205,205);
  --color-header-d: rgb(235, 235, 235);
  --color-secondary-d: rgb(147, 151, 156);
  --color-tertiary-d: rgb(121, 124, 125);
  --color-yellow-text-d: var(--color-yellow);
  --box-shadow-d: none;
  --box-shadow-dark-d: none;
  --float-border-d: 1px solid var(--bc);

  
  /* blues mode */
  --bgc-b: #0e2439;
  --bgc-light-b: #1f364d;
  --bgc-semilight-b: #182e43;
  --bgc-hover-b: rgb(249,252,255, 0.08);
  --bgc-nav-b: var(--bgc-semilight-b); 
  --bgc-settings-b: var(--bgc-b);
  --bgc-input-b: #284059;
  --bc-b: #325070;
  --bc-chart-b: #182e45;
  --color-b: rgb(225,225,225);
  --color-header-b: rgb(245, 245, 245);
  --color-tertiary-b: #63809c;
  --color-secondary-b: #9cb3c9;
  --color-yellow-text-b: var(--color-yellow);
  --box-shadow-b: none;
  --box-shadow-dark-b: none;
  --float-border-b: none;

  /*light mode*/
  --bgc-l: rgb(247, 249, 250);
  --bgc-l: rgb(244, 246, 248);
  --bgc-light-l: white;/* firebase */
  --bgc-hover-l: rgb(245,247,249);
  --bgc-semilight-l: rgb(248, 250, 252);
  --bgc-nav-l: var(--bgc-light-l);
  --bgc-settings-l: white;
  --bgc-input-l: rgb(225, 228, 232); /* twitter */
  --bgc-input-l: rgb(235, 238, 240);
  --bc-l: rgb(220, 227, 231);
  --bc-l: rgb(215, 222, 226);
  --bc-chart-l: rgb(230, 230, 230);
  --color-l: rgb(60,64,67);
  --color-header-l: rgb(30, 34, 37);
  --color-header-l: rgb(10, 12, 14);
  --color-secondary-l: rgba(120,124,126,255);
  --color-secondary-l: rgba(135,138,140,255);
  --color-tertiary-l: rgb(102,102,102);
  --color-yellow-text-l: #85681d;

  --float-border-l: 1px solid var(--bc);

  --box-shadow-l: 0px 1px 4px 0px rgba(0,0,0,0.2);
  --box-shadow-l: 0 0 20px rgba(0,0,0,0.05);
  --float-border-l: none;


  /* dynamic color scheme */
  --bgc-nav: var(--bgc-nav-l);
  --bgc: var(--bgc-l);
  --bgc-light: var(--bgc-light-l);
  --bgc-semilight: var(--bgc-semilight-l);
  --bgc-hover: var(--bgc-hover-l);
  --bgc-settings: var(--bgc-settings-l);
  --bgc-input: var(--bgc-input-l);
  --bc: var(--bc-l);
  --bc-chart: var(--bc-chart-l);
  --color: var(--color-l);
  --color-header: var(--color-header-l);
  --color-secondary: var(--color-secondary-l);
  --color-tertiary: var(--color-tertiary-l);
  --color-yellow-text: var(--color-yellow-text-l);
  --box-shadow: var(--box-shadow-l);
  --box-shadow-dark: var(--box-shadow-dark-l);
  --float-border: var(--float-border-l);

  /* standard colors */

  --color-red: rgb(253, 80, 0);
  --color-translucent-red: rgba(253, 80, 0, 0.2);

  --color-red: #f04747;
  --color-translucent-red: rgba(240, 71, 71, 0.2);

  --color-error: #ea374d;
  --color-error: rgb(253, 80, 0); /* robinhood */

  --color-error: var(--color-red);

  --color-success: #0f8a25;
  --color-success: rgb(23, 169, 86);
  --page-link-color: rgb(0, 115, 207);
  --page-link-color: rgb(70,141,228);
  
  /* apple notes yellow */
  --color-yellow: rgb(254, 187, 18);
  --color-translucent-yellow: rgb(254, 187, 18, 0.2);

  --color-strava: #0e7fb6;
  --color-translucent-strava: rgb(14, 127, 182, 0.2);

  --color-green: rgb(14, 137, 37);
  --color-translucent-green: rgb(14, 137, 37, 0.2);
  --color-green: #17a956;
  --color-translucent-green: rgba(23, 169, 86, 0.2);


  --color-yellow: rgb(254, 221, 74);
  --color-translucent-yellow: rgba(254, 221, 74, 0.3);
  --color-yellow-text: #85681d;

  --color-discord: #7288d9;
  --colro-discord: #607de4;
  --color-translucent-discord: rgb(114, 137, 218, 0.25);

  --color-purple: #5469d4;
  --color-translucent-purple: rgb(84, 105, 212, 0.2);

  --color-apple-blue: rgb(38, 135, 250);
  --color-translucent-apple-blue: rgb(38, 135, 250, 0.2);

  --color-rh-blue: rgb(14, 121, 211);
  --color-translucent-rh-blue: rgb(14, 121, 211, 0.2);

  --color-lime: rgb(195,245,60);
  --color-translucent-lime: rgba(195,245,60,0.2);

  --color-teal: rgb(122, 215, 189);
  --color-translucent-teal: rgba(122, 215, 189, 0.2);

  --color-twitter-blue: rgb(30,162,242);
  --color-translucent-twitter-blue: rgba(30,162,242,0.2);

  --color-mint: #189e8a;
  --color-translucent-mint: rgba(24, 158, 138, 0.2);

  --color-default: #5a9ff6;
  --color-translucent-default: rgba(90, 159, 246, 0.2);

  --color-landing-gray: #6f787d;


  /* misc global */
  --ps-nav: 200px;
  --ps-main: 200px;
  --ps-small: 50px;
  --main-nav-height: 70px;

  --ps-nav: 13%;
  --ps-main: 12%;
  --ps-small: 3%;

  --container-border-radius: 5px;

  /* Nunito Sans */
  --fw-xxs: 200;
  --fw-xs: 300;
  --fw-s: 400;
  --fw-m: 600;
  --fw-l: 700;
  --fw-xl: 800;
}


/* main logo */
.logo-text {
  text-transform: capitalize;
  font-weight: 400;
  letter-spacing: 1px;
  color: rgb(10, 12, 14);
  color: var(--color-header);
  cursor: pointer;
}



input, input:active, input:focus,
textarea, textarea:active, textarea:focus,
select, select:active, select:focus
 {
  color: rgb(60,64,67);
  color: var(--color);
  border: 1px solid transparent;
  border-radius: 5px;
  padding: 11px 10px;
  outline: none;
  font-size: 14px;
  background-color: rgb(235, 238, 240);
  background-color: var(--bgc-input);
  margin-top: 5px;
}
input:focus, input:active,
textarea:active, textarea:focus,
select:active, select:focus
{
  border-color: rgb(30,162,242);
  border-color: var(--tint-color);
  background-color: transparent;
  transition: 0.2s;
}


.search-bar {
  border: 1px solid rgb(215, 222, 226);
  border: 1px solid var(--bc);
  border-radius: 5px;
  padding-left: 10px;
}
.search-bar:hover {
  border-color: rgb(30,162,242);
  border-color: var(--tint-color);
}
.search-bar input {
  background-color: transparent;
  margin-top: 0px;
}

form label {
  font-weight: 400;
  color: rgb(60,64,67);
  color: var(--color);
  font-size: 14px;
}

.main-header {
  background-color: white;
  background-color: var(--bgc-nav);
  height: 70px;
  height: var(--main-nav-height);
  color: rgb(60,64,67);
  color: var(--color);
  padding: 0px 3%;
  padding: 0px var(--ps-small);
  box-shadow: 0 0 20px rgba(0,0,0,0.05);
  box-shadow: var(--box-shadow);
  top: 0px;

}
.landing-header {
  background-color: white;
  background-color: var(--bgc-nav);
  color: rgb(60,64,67);
  color: var(--color);
  padding: 10px 3%;
  padding: 10px var(--ps-small);
  box-shadow: 0 0 20px rgba(0,0,0,0.05);
  box-shadow: var(--box-shadow);
  box-shadow: 0px 1px 4px 0px rgba(0,0,0,0.2);
  top: 0px;
  position: -webkit-sticky;
  position: sticky;
  height: 70px;
  height: var(--main-nav-height);
  box-sizing: border-box;
}
.sub-header {
  background-color: white;
  background-color: var(--bgc-nav);
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  text-align: left;
  padding-top: 13px;
  padding-right: 13%;
  padding-right: var(--ps-nav);
  padding-left: 13%;
  padding-left: var(--ps-nav);
  color: rgb(60,64,67);
  color: var(--color);
  border-bottom: 1px solid rgb(215, 222, 226);
  border-bottom: 1px solid var(--bc);
  z-index: 1;
}
.sub-link-active {
  border-bottom: 2px solid rgb(30,162,242);
  border-bottom: 2px solid var(--tint-color);
}
.header-link {
  color: rgb(10, 12, 14);
  color: var(--color-header);
  font-weight: 400;
  font-size: 15px;
  text-decoration: none;
  margin-right: 25px;
}
.header-link:hover,
.link-active {
  color: rgb(30,162,242);
  color: var(--tint-color);
  transition: 0.2s;
}

.page-link {
  text-decoration: none;
  color: rgb(60,64,67);
  color: var(--color);
  cursor: pointer;
}
.page-link:hover {
  color: rgb(70,141,228);
  color: var(--page-link-color);
}

.column-scrollable-grid {
  -ms-scroll-chaining: chained;
      overscroll-behavior: auto;
}
.column-scrollable-grid .scrollable-grid-column {
  max-height: 100vh;
  overflow: scroll;
}
.main-container {
  text-align: left;
  margin: 0px auto;
  padding-right: 12%;
  padding-right: var(--ps-main);
  padding-left: 12%;
  padding-left: var(--ps-main);
  color: rgb(60,64,67);
  color: var(--color);
}

.main-subcontainer {
  border: 1px solid rgb(215, 222, 226);
  border: 1px solid var(--bc);
  border-radius: 5px;
}
.main-subcontainer:hover {
  background-color: rgb(245,247,249);
  background-color: var(--bgc-hover);
  border-color: rgb(245,247,249);
  border-color: var(--bgc-hover);
}

.float-container {
  border-radius: 5px;
  border-radius: var(--container-border-radius);
  box-shadow: none;
  border: none;
  border: var(--float-border);
  padding: none;
  overflow: hidden;
  background-color: white;
  background-color: var(--bgc-light);

  box-shadow: 0 0 20px rgba(0,0,0,0.05);

  box-shadow: var(--box-shadow);
}

.bgc-container {
  border-radius: 5px;
  border-radius: var(--container-border-radius);
  border: 1px solid rgb(215, 222, 226);
  border: 1px solid var(--bc);
  background-color: rgb(244, 246, 248);
  background-color: var(--bgc);
  overflow: hidden;
}
.trans-container {
  border-radius: 5px;
  border-radius: var(--container-border-radius);
  border: 1px solid rgb(215, 222, 226);
  border: 1px solid var(--bc);
  padding: 10px 10px;
}
.clickable-container {
  padding: 10px 10px;
  cursor: pointer;
}
.clickable-container:hover {
  background-color: rgb(245,247,249);
  background-color: var(--bgc-hover);
}

/* landing page */
.landing-page {
}
.landing-container-12,
.landing-container-11{
  padding: 75px 100px;
  text-align: left;
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: 30px;
  gap: 30px;
  margin: none;
  border-bottom: 1px solid black;
}
.landing-container-12 {
  grid-template-columns: 1fr 2fr;
}
.landing-container-11 {
  grid-template-columns: 1fr 1fr;
}

@media only screen and (max-width: 950px) {
  .landing-container-12,
  .landing-container-11 {
    grid-template-columns: 1fr;
    padding: 50px 8%;
  }
  .landing-page div {
    grid-column: 1;
  }
}
.landing-page .solid-btn-secondary,
.landing-page .solid-btn {
  font-weight: 700;
  font-weight: var(--fw-l);
}
.landing-page .c-cs {
  color: #6f787d;
  color: var(--color-landing-gray);
}
.landing-tab-option,
.landing-tab-option-selected {
  color: #6f787d;
  color: var(--color-landing-gray);
  font-weight: 600;
  font-weight: var(--fw-m);
  cursor: pointer;
}
.landing-tab-option-selected {
  color: rgb(10, 12, 14);
  color: var(--color-header);
}
.landing-tab-option:hover {
  color: rgb(30,162,242);
  color: var(--tint-color);
}
.landing-hero-section {
  background-color: white;
}
.landing-how-section{
  background-color: #f0ebe7;
  background-color: rgba(30,162,242,0.2);
  background-color: var(--tint-color-translucent);
}
.landing-how-section * {
  border-color: black !important;
}
.landing-questions-section {
  background-color: white;
}

/* login / reg / password reset */
.login-page {
  min-height: 100vh;
}
.login-card {
  box-sizing: border-box;
  width: 450px;
  flex: 1 1;
  padding: 40px 40px;
  text-align: left;
  margin: 0 auto;
  border: 1px solid rgb(215, 222, 226);
  border: 1px solid var(--bc);
}

@media only screen and (max-width: 500px) {
  .login-card {
    width: 95vw;
  }
}

.login-card input {
  width: 100%;
  box-sizing: border-box;
}
.login-card button {
  box-sizing: border-box;
  width: 100%;
}
.login-card h3 {
  font-weight: 500;
}
.action-link {
  display: inline;
  color: rgb(30,162,242);
  color: var(--tint-color);
  cursor: pointer;
}
.action-link:hover {
  text-decoration: underline;
}
.login-spacer {
  height: 100px;
}

/* dashbaord */

/* dropdown menu */
.dropdown-menu-section-title {
  padding: 10px 20px;
  text-transform: uppercase;
  font-weight: 600;
  font-weight: var(--fw-m);
  color: rgba(135,138,140,255);
  color: var(--color-secondary);
}
.dropdown-menu p {
  color: rgb(10, 12, 14);
  color: var(--color-header);
}


/* settings */
.settings-page {
  background-color: white;
  background-color: var(--bgc-nav);
  min-height: 100vh;
  padding-top: 50px;
}
.settings-menu {
  position: -webkit-sticky;
  position: sticky;
}
.settings-page h3 {
  font-weight: 400;
}
.settings-page .clear-btn-secondary,
.settings-page .error-btn-secondary {
  border: none;
  font-weight: 500;
}
.settings-page input {
  width: 200px;
}
.settings-page .menu-link {
  cursor: pointer;
  color: rgb(10, 12, 14);
  color: var(--color-header);
}
.settings-page .menu-link:hover {
  color: rgb(30,162,242);
  color: var(--tint-color);
}
.settings-list .settings-row:last-child {
  border-bottom: 1px solid rgb(215, 222, 226);
  border-bottom: 1px solid var(--bc);
}
.settings-list {
  margin-bottom: 60px;
}
.settings-row,
.editable-settings-row {
  border-top: 1px solid rgb(215, 222, 226);
  border-top: 1px solid var(--bc);
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 50px;
  padding: 0px 15px;
}
.editable-settings-row:hover {
  cursor: pointer;
  background-color: rgb(245,247,249);
  background-color: var(--bgc-hover);
}
.editable-settings-row p:first-child,
.settings-row p:first-child {
  color: rgba(135,138,140,255);
  color: var(--color-secondary);
}
.settings-edit-container {
  background-color: white;
  background-color: var(--bgc-settings);
  border-radius: 5px;
  box-shadow: var(--box-shadow-dark-l);
  box-shadow: var(--box-shadow-dark);
  border: none;
  border: var(--float-border);
  border: 1px solid rgb(215, 222, 226);
  border: 1px solid var(--bc);
  padding: 0px 15px;
  overflow: hidden;
}
.settings-edit-header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 15px 0px;
  cursor: pointer;
  border-bottom: 1px solid rgb(215, 222, 226);
  border-bottom: 1px solid var(--bc);
}
.settings-edit-header * {
  margin-right: 20px;
}

/* activity card */
.activity-card {
  padding: 15px 15px;
  margin-bottom: 30px;
}
.activity-card .metrics-info div{
  margin-right: 20px;
  border-right: 1px solid rgb(215, 222, 226);
  border-right: 1px solid var(--bc);
  border-right: none;
  padding-right: 20px;
}
.activity-card .metrics-info div:last-child {
  border-right: none;
  padding-right: 0px;
  margin-right: 20px;
}
.activity-card .metrics-info p {
  color: rgba(135,138,140,255);
  color: var(--color-secondary);
  margin-bottom: 3px;
}
.activity-card .metrics-info h3 {
  color: rgb(30,162,242);
  color: var(--tint-color);
}
/* comment section */
.comment-icons div {
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
}
.comment-icons div:hover,
.reply-icons div:hover {
  background-color: rgb(245,247,249);
  background-color: var(--bgc-hover);
}
.comment-icons i {
  margin-right: 6px;
  color: rgba(135,138,140,255);
  color: var(--color-secondary);
  font-size: 14px;
}
.comment-icons h5 {
  color: rgba(135,138,140,255);
  color: var(--color-secondary);
  font-weight: 500;
}
.reply-icons {
  margin-left: -4px;
}
.reply-icons div {
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
}
.reply-icons i{
  margin-right: 6px;
  color: rgba(135,138,140,255);
  color: var(--color-secondary);
  font-size: 11px;
}
.reply-icons h5 {
  color: rgba(135,138,140,255);
  color: var(--color-secondary);
  font-weight: 400;
}

/* chat */
.chat-container {
  text-align: left;
  box-sizing: border-box;
}
.chat-header {
  box-sizing: border-box;
  height: 45px;
  background-color: white;
  background-color: var(--bgc-nav);
  padding: 12px 20px; padding-left: 10px;
  width: 100%;
  border-bottom: 1px solid rgb(215, 222, 226);
  border-bottom: 1px solid var(--bc);
  border-top: 1px solid rgb(215, 222, 226);
  border-top: 1px solid var(--bc);
}
.chat-header .toggle-menu-icon {
  padding: 0px 3px;
  color: rgba(135,138,140,255);
  color: var(--color-secondary); font-size: 20px;
  cursor: pointer;
}
.chat-header .toggle-menu-icon:hover {
  background-color: rgb(245,247,249);
  background-color: var(--bgc-hover);
}
.channels-wrapper {
  position: absolute;
  width: 100%;
  transition: all ease 0.2s;
  overflow: hidden;
  z-index: 1;
}
.channels-container {
  box-sizing: border-box;
  padding: 15px 25px; padding-bottom: 30px;
  border-bottom: 1px solid rgb(215, 222, 226);
  border-bottom: 1px solid var(--bc);
  background-color: rgb(235, 238, 240);
  background-color: var(--bgc-input);
  width: 85%;
  border: 1px solid rgb(215, 222, 226);
  border: 1px solid var(--bc);
  border-left: none;
}
.channels-container * {
  white-space: nowrap;
}
.channel-option {
  cursor: pointer;
  padding: 5px 10px;
  border-radius: 3px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.channel-option i{
  color: #f04747;
  color: var(--color-red);
}
.channel-option:hover {
  background-color: rgb(245,247,249);
  background-color: var(--bgc-hover);
}
.messages-container {
  overflow: scroll;
  box-sizing: border-box;
  padding-top: 15px;
  padding-bottom: 60px;
  background-color: white;
  background-color: var(--bgc-nav);
}
.message-text-container {
  padding: 10px 10px;
  overflow-wrap: break-word;
  line-height: 1.6;
  border: 1px solid rgb(215, 222, 226);
  border: 1px solid var(--bc);
  box-shadow: none;
}
.message-input-container {
  background-color: rgb(235, 238, 240);
  background-color: var(--bgc-input);
  border-radius: 20px;
  overflow: hidden;
  margin-top: -20px;
  display: block;
  margin-right: 15px; margin-left: 15px;
}
.icon-tab-option,
.icon-tab-option-selected {
  cursor: pointer;
  color: rgba(135,138,140,255);
  color: var(--color-secondary);
  margin-left: 18px;
  border-radius: 10px;
  font-size: 18px;
}
.icon-tab-option:hover,
.icon-tab-option-selected {
  color: rgb(30,162,242);
  color: var(--tint-color);
}
.self-chat-message {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  padding-right: 10px;
  padding-left: 20%;
  margin-bottom: 15px;
}
.notself-chat-message {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding-right: 20%;
  padding-left: 0px;
  margin-bottom: 15px;
}
.self-chat-message .message-text-container {
  background-color: rgba(30,162,242,0.2);
  background-color: var(--tint-color-translucent);
  border-color: rgba(30,162,242,0.2);
  border-color: var(--tint-color-translucent);
  color: rgb(60,64,67);
  color: var(--color);
}
.notself-chat-message .message-text-container {
  background-color: white;
  background-color: var(--bgc-light);
  color: rgb(60,64,67);
  color: var(--color);
}


/* club page */
.banner-image {
  width: 100%;
  display: block;
  object-fit: cover;
  object-position: center;
  max-height: 160px;
}

/* club members page */
.member-card {
  padding: 10px 20px;
}
.member-card:hover {
  background-color: rgb(245,247,249);
  background-color: var(--bgc-hover);
}
.member-card .action-buttons {
  visibility: hidden;
  flex-wrap: wrap;
}
.member-card:hover .action-buttons {
  visibility: visible;
}

/* create club page */
.icon-image-input {
  margin-top: 5px;
  cursor: pointer;
  height: 75px; width: 75px;
  border: 2px dashed rgb(215, 222, 226);
  border: 2px dashed var(--bc);
  border-radius: 5px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: rgb(30,162,242);
  color: var(--tint-color);
  font-size: 30px;
  overflow: hidden;
}
.icon-image-input img {
  height: 100%; width: 100%;
  object-fit: cover;
  object-position: center;
}
.banner-image-input {
  margin-top: 5px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  border: 2px dashed rgb(215, 222, 226);
  border: 2px dashed var(--bc);
  color: rgb(30,162,242);
  color: var(--tint-color);
  font-size: 50px;
  overflow: hidden;
}
.banner-image-input img {
  width: 100%;
  max-height: 125px;
  object-position: center;
  object-fit: cover;
}

/* edit club page */
.modified-label,
.yellow-message {
  padding: 3px 10px;
  font-weight: 400;
  border-radius: 7px;
  display: inline;
  margin-left: 7px;
  background-color: rgba(254, 221, 74, 0.3);
  background-color: var(--color-translucent-yellow);
  color: #85681d;
  color: var(--color-yellow-text);
}

/* session page */
.bc-t {
  border-color: transparent;
}
.session-page {
  background-color: white;
  background-color: var(--bgc-light);
}
.session-page .session-sections {
  background-color: rgb(244, 246, 248);
  background-color: var(--bgc);
  min-height: 100vh;
}
.session-page .session-section-container {
  margin: 0px 20px;
  padding: 10px 10px;
}
.session-page .session-section-title {
  display: block;
  padding: 12px 20px;
  margin-bottom: 10px;
}
.session-page .workout-menu {
  background-color: white;
  background-color: var(--bgc-nav);
  border-right: 1px solid rgb(215, 222, 226);
  border-right: 1px solid var(--bc);
}
.workout-table tbody {
  background-color: rgb(244, 246, 248);
  background-color: var(--bgc);
}
.empty-table-message {
  padding: 15px 20px;
  color: rgba(135,138,140,255);
  color: var(--color-secondary); background-color: rgb(244, 246, 248); background-color: var(--bgc);
  text-align: center;
}

/* statistics page */
.statistics-page .stats-metric-option,
.statistics-page .stats-metric-option-selected {
  cursor: pointer;
  text-align: center;
  border-top: 2px solid transparent;
  border-right: 1px solid rgb(215, 222, 226);
  border-right: 1px solid var(--bc);
  padding: 10px 0px;
}
.statistics-page .stats-metric-option-selected {
  border-top-color: rgb(30,162,242);
  border-top-color: var(--tint-color);
}

/* tables */
table {
  border-collapse: collapse;
  text-align: left;
}
th,td {
  padding: 13px;
  font-size: 14px;
}
.data-table th, .data-table td {
  padding: 13px;
}

th {
  border-bottom: 1px solid rgb(215, 222, 226);
  border-bottom: 1px solid var(--bc);
  color: rgb(102,102,102);
  color: var(--color-tertiary);
  font-weight: 500;
  font-weight: 400;
  letter-spacing: 1px;
  font-size: 12px;
}

.th-sortable{
  cursor: pointer;
}
.th-sortable:hover {
  color: rgb(60,64,67);
  color: var(--color);
}

.th-selected {
  color: rgb(30,162,242);
  color: var(--tint-color);
  border-bottom: 2px solid rgb(30,162,242);
  border-bottom: 2px solid var(--tint-color);
}

tr {
  color: rgb(60,64,67);
  color: var(--color);
}
tbody tr:hover{
  background-color: rgb(245,247,249);
  background-color: var(--bgc-hover);
}
td:first-child {
  color: rgba(135,138,140,255);
  color: var(--color-secondary);
}
.data-table tbody tr,
table tbody tr {
  border-bottom: 1px solid rgb(215, 222, 226);
  border-bottom: 1px solid var(--bc);
}
.data-table tbody tr:last-child, 
table tbody tr:last-child {
  border-bottom: none;
}

/* stats time option */
.menu-option {
  border-bottom: 2px solid transparent;
  padding-bottom: 8px;
  transition: 0.2s;
  cursor: pointer;
  font-weight: 600;
  color: rgba(135,138,140,255);
  color: var(--color-secondary);
}
.menu-option:hover {
  color: rgb(60,64,67);
  color: var(--color);
  
  color: rgb(30,162,242);
  
  color: var(--tint-color);
}
.menu-option-active {
  border-bottom: 2px solid rgb(30,162,242);
  border-bottom: 2px solid var(--tint-color);
  padding-bottom: 8px;
  transition: 0.2s;
  cursor: pointer;
  font-weight: 600;
}

/* tooltip */

.tooltip {
  display: inline-flex;
  justify-content: center;
  align-items: flex-start;
  cursor: pointer;
}
.tooltip:hover .tooltip-text {
  visibility: visible;
}
.tooltip .tooltip-text *{
  color: rgb(10, 12, 14);
  color: var(--color-header);
}
.tooltip .tooltip-text {
  /*opacity: 0%;*/
  visibility: hidden;
  box-sizing: border-box;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0px 10px;
  height: 30px;
  margin-top: -34px;
  border-radius: 5px;
  background-color: white;
  background-color: var(--bgc-light);
  border: 1px solid rgba(48,54,58,255);
  border: 1px solid var(--bc-d);
  pointer-events: none;
  position: absolute;
  overflow: auto;
  white-space: nowrap;

  box-shadow: var(--box-shadow-dark-l);

  box-shadow: var(--box-shadow-dark);
  border: 1px solid rgb(215, 222, 226);
  border: 1px solid var(--bc);
}

/* loading message */
.loading-message {
  padding: 15px 10px;
  color: rgb(30,162,242);
  color: var(--tint-color);
  font-size: 15px;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  margin-right: 7px;
}
.loading-message * {
  margin-right: 10px;
}

/* empty search message */
.search-message {
  color: rgba(135,138,140,255);
  color: var(--color-secondary);
  font-size: 17;
  font-weight: 300;
  padding: 30px 20px;
  text-align: center;
}

/* icons */
.user-icon, .user-icon-small,
.club-icon {
  display: inline;
  height: 35px;
  width: 35px;
  border-radius: 50%;
  margin-right: 15px;
  object-fit: cover;
  object-position: center;
}
.club-icon {
  border-radius: 5px;
}
.club-icon-medium {
  height: 50px;
  width: 50px;
  object-fit: cover;
  object-position: center;
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid transparent;
}
.club-icon-medium:hover {
  border-color: rgb(30,162,242);
  border-color: var(--tint-color);
  box-shadow: var(--box-shadow-dark-l);
  box-shadow: var(--box-shadow-dark);
}
.user-icon-default {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 35px;
  width: 35px;
  border-radius: 50%;
  background-color: rgba(30,162,242,0.2);
  background-color: var(--tint-color-translucent);
  color: rgb(30,162,242);
  color: var(--tint-color);
  font-size: 20px;
  margin-right: 15px;
}
.user-icon-small {
  display: inline;
  height: 25px; width: 25px;
  border-radius: 50%;
  margin-right: 10px;
  object-fit: cover;
  object-position: center;
}
.user-icon-default-small {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  background-color: rgba(30,162,242,0.2);
  background-color: var(--tint-color-translucent);
  color: rgb(30,162,242);
  color: var(--tint-color);
  font-size: 15px;
  margin-right: 10px;
}

/* random margins */
.mr-2 {
  margin-right: 2px;
}
.mr-3 {
  margin-right: 3px;
}
.mr-5 {
  margin-right: 5px;
}
.mr-7 {
  margin-right: 7px;
}
.mr-10 {
  margin-right: 10px;
}
.mr-15 {
  margin-right: 15px;
}
.mr-20 {
  margin-right: 20px;
}
.mr-30 {
  margin-right: 30px;
}
.ml-2 {
  margin-left: 2px;
}
.ml-3 {
  margin-left: 3px;
}
.ml-5 {
  margin-left: 5px;
}
.ml-7 {
  margin-left: 7px;
}
.ml-10 {
  margin-left: 10px;
}
.ml-15 {
  margin-left: 15px;
}
.ml-20 {
  margin-left: 20px;
}
.ml-30 {
  margin-left: 30px;
}
.mb-2 {
  margin-bottom: 2px;
}
.mb-3 {
  margin-bottom: 3px;
}
.mb-5 {
  margin-bottom: 5px;
}
.mb-7 {
  margin-bottom: 7px;
}
.mb-10 {
  margin-bottom: 10px;
}
.mb-15 {
  margin-bottom: 15px;
}
.mb-20 {
  margin-bottom: 20px;
}
.mb-30 {
  margin-bottom: 30px;
}

/* display flex */
.d-flex {
  display: flex;
}
.d-inline-flex {
  display: inline-flex;
}
.d-inline {
  display: inline;
}
.d-block {
  display: block;
}

.jc-center {
  justify-content: center;
}
.jc-flex-start {
  justify-content: flex-start;
}
.jc-flex-end {
  justify-content: flex-end;
}
.jc-space-around {
  justify-content: space-around;
}
.jc-space-between {
  justify-content: space-between;
}
.ai-center {
  align-items: center;
}
.ai-flex-start {
  align-items: flex-start;
}
.ai-flex-end {
  align-items: flex-end;
}
.fw-wrap {
  flex-wrap: wrap;
}


.ls-none {
  list-style: none;
}
.td-none {
  text-decoration: none;
}

.d-inline{
  display: inline;
}
.bs-bb {
  box-sizing: border-box;
}
/* margins */
.ps-small {
  padding-left: 3%;
  padding-left: var(--ps-small);
  padding-right: 3%;
  padding-right: var(--ps-small);
}

/*  Colors  */
.bgc-light {
  background-color: white;
  background-color: var(--bgc-light);
}
.bgc {
  background-color: rgb(244, 246, 248);
  background-color: var(--bgc);
}

/*  Buttons: 4 main types */
.solid-btn i, .solid-btn img,
.clear-btn i, .clear-btn img,
.solid-btn-secondary i, .solid-btn-secondary img,
.clear-btn-secondary i, .clear-btn-secondary img {
  margin-right: 10px;
}
.solid-btn,
.clear-btn,
.solid-btn-secondary,
.clear-btn-secondary,
.clear-btn-cancel {
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 10px 25px;
  background-color: rgb(30,162,242);
  background-color: var(--tint-color);
  color: white;
  color: var(--bgc-light);
  font-size: 17px;
  border-radius: 5px;
  color: white;
  color: var(--bgc-settings);
  border: 1px solid rgb(30,162,242);
  border: 1px solid var(--tint-color);
  border-radius: 5px;
}
.clear-btn,
.clear-btn-secondary,
.clear-btn-cancel {
  background-color: transparent;
  color: rgb(30,162,242);
  color: var(--tint-color);
  border: 1px solid rgb(30,162,242);
  border: 1px solid var(--tint-color);

  border: 1px solid rgb(215, 222, 226);

  border: 1px solid var(--bc);
  background-color: white;
  background-color: var(--bgc-light);
}
.solid-btn-secondary,
.clear-btn-secondary,
.error-btn-secondary,
.clear-btn-cancel {
  padding: 7px 20px;
  font-size: 14px;
}
.solid-btn:hover,
.solid-btn-secondary:hover {
  -webkit-filter: brightness(80%);
          filter: brightness(80%);
  transition: 0.4s;
}
.clear-btn:hover,
.clear-btn-secondary:hover {
  background-color: rgba(30,162,242,0.2);
  background-color: var(--tint-color-translucent);
  transition: 0.3s;
}
.clear-btn-cancel {
  background-color: transparent;
  color: rgb(30,162,242);
  color: var(--tint-color);
  border: none;
}
.clear-btn-cancel:hover {
  background-color: rgba(30,162,242,0.2);
  background-color: var(--tint-color-translucent);
  transition: 0.3s
}
.clear-icon-btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid rgb(30,162,242);
  border: 1px solid var(--tint-color);
  font-size: 16px;
  height: 30px; width: 30px;
  color: rgb(30,162,242);
  color: var(--tint-color);
}
.clear-icon-btn:hover {
  background-color: rgba(30,162,242,0.2);
  background-color: var(--tint-color-translucent);
}
.solid-icon-btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid rgb(30,162,242);
  border: 1px solid var(--tint-color);
  font-size: 16px;
  height: 30px; width: 30px;
  color: rgb(244, 246, 248);
  color: var(--bgc);
  background-color: rgb(30,162,242);
  background-color: var(--tint-color);
}
.solid-icon-btn:hover {
  -webkit-filter: brightness(80%);
          filter: brightness(80%);
}
.icon-btn {
  cursor: pointer;
  text-align: center;
  background-color: transparent;
  color: rgb(30,162,242);
  color: var(--tint-color);
  font-size: 22px;
  border-radius: 5px;
  border: none;
  padding: 5px 14px;
}
.icon-btn:hover {
  background-color: rgba(30,162,242,0.2);
  background-color: var(--tint-color-translucent);
  transition: 0.3s;
}
.icon-btn-circle {
  cursor: pointer;
  height: 30px; width: 30px;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  color: rgba(135,138,140,255);
  color: var(--color-secondary);
  border: none;
}
.icon-btn-circle:hover {
  background-color: rgb(245,247,249);
  background-color: var(--bgc-hover);
}

.error-btn-secondary {
  color: #f04747;
  color: var(--color-red);
  background-color: transparent;
  border: 1px solid #f04747;
  border: 1px solid var(--color-red);
  border-radius: 5px;
}
.error-btn-secondary:hover {
  transition: 0.3s;
  background-color: rgba(240, 71, 71, 0.2);
  background-color: var(--color-translucent-red);
}
.paginator-button {
  color: rgb(30,162,242);
  color: var(--tint-color);
  background-color: transparent;
  cursor: pointer;
}
.paginator-button:hover {
  background-color: rgba(30,162,242,0.2);
  background-color: var(--tint-color-translucent);
}

/* small icons */
.icon-circle-clear {
  height: 16px;
  width: 16px;
  border-radius: 50%;
  border: 2px solid;
  padding: 6px;
}

.icon-circle {
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background-color: rgb(30,162,242);
  background-color: var(--tint-color);
  padding: 4px;
}

.checkmark {
  /* white check */
  display: inline-block;
  transform: rotate(40deg);
  height: 12px;
  width: 5px;
  margin-bottom: 2px;
  margin-left: 2px;
  border-bottom: 2px solid;
  border-right: 2px solid;
  border-color: inherit;
}

.xmark {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-color: inherit;
}


/* valid for h3 */
.number-item {
  display: inline-flex;
  justify-content: center; align-items: center;
  padding: 3px 9px;
  color: rgba(135,138,140,255);
  color: var(--color-secondary);
  border: 1px dashed rgba(135,138,140,255);
  border: 1px dashed var(--color-secondary);
  border-radius: 50%;
}

/* random props */
.onhover-bgc-hover:hover {
  background-color: rgb(245,247,249);
  background-color: var(--bgc-hover);
}
.onhover-bgc-tc:hover {
  background-color: rgb(30,162,242);
  background-color: var(--tint-color);
}
.onhover-bgc-tct:hover {
  background-color: rgba(30,162,242,0.2);
  background-color: var(--tint-color-translucent);
}

.onhover-c-tc:hover {
  color: rgb(30,162,242);
  color: var(--tint-color);
}
.onhover-c-w:hover {
  color: rgb(235, 235, 235) !important;
  color: var(--color-header-d) !important;
}

.onhover-bc {
  border: 1px solid transparent;
  border-radius: 5px;
}
.onhover-bc:hover {
  border-color: rgb(215, 222, 226);
  border-color: var(--bc);
}

.no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}



/* random slider stuff */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .2s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .2s;
}

input:checked + .slider {
  background-color: rgb(30,162,242);
  background-color: var(--tint-color);
  ;
}

input:focus + .slider {
  box-shadow: 0 0 1px rgb(30,162,242);
  box-shadow: 0 0 1px var(--tint-color);
  ;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

/* ios style toggle switch */
.toggle-control {
  border-radius: 5px;
  background-color: rgb(235, 238, 240);
  background-color: var(--bgc-input);
  border: 1px solid rgb(215, 222, 226);
  border: 1px solid var(--bc);
  display: inline-flex;
  justify-content: center;
  overflow: hidden;
}
.toggle-option, 
.toggle-option-selected {
  color: rgba(135,138,140,255);
  color: var(--color-secondary);
  padding: 8px 15px;
  cursor: pointer;
}
.toggle-option-selected {
  color: white;
  background-color: rgb(30,162,242);
  background-color: var(--tint-color);
}

/* random */
.rotate-180 {
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
}
.onhover-bright:hover {
  -webkit-filter: brightness(125%);
          filter: brightness(125%);
}
.onhover-dark:hover {
  -webkit-filter: brightness(85%);
          filter: brightness(85%);
}
.tt-u {
  text-transform: uppercase;
}
.tt-c {
  text-transform: capitalize;
}
.tt-l {
  text-transform: lowercase;
}
.lc-1 {
  letter-spacing: 1px;
}
.lc-2 {
  letter-spacing: 2px;
}
.c-tc {
  color: rgb(30,162,242);
  color: var(--tint-color);
}
.c-cs {
  color: rgba(135,138,140,255);
  color: var(--color-secondary);
}
.c-ct {
  color: rgb(102,102,102);
  color: var(--color-tertiary);
}
.c-c {
  color: rgb(60,64,67);
  color: var(--color);
}
.c-yt {
  color: #85681d;
  color: var(--color-yellow-text);
}
.fw-xxs {
  font-weight: 200;
}
.fw-xs {
  font-weight: 300;
}
.fw-s {
  font-weight: 400;
}
.fw-m {
  font-weight: 600;
}
.fw-l {
  font-weight: 700;
}
.fw-xl {
  font-weight: 800;
}
.w-100 {
  width: 100%;
}
.ta-l {
  text-align: left;
}
.ta-r {
  text-align: right;
}
.ta-c {
  text-align: center;
}


/* popup windows */
.fullscreen-blur {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0px;
  left: 0px;
  -webkit-backdrop-filter: blur(3px) brightness(65%);
          backdrop-filter: blur(3px) brightness(65%);
  z-index: 21;
}

/* children */
.last-div-no-br div:last-child {
  border-right: none !important;
}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 50px;
  }
  .lds-ellipsis div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: darkgray;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
  }
  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }
.lds-ring {
    display: inline-block;
    position: relative;
    width: 25px;
    height: 25px;
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 25px;
    height: 25px;
    border: 3px solid var(--tint-color);
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: var(--tint-color) transparent transparent transparent;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
/*
    **** REQUIRED :root vars ****
    --bgc-input
    --tint-color
    --tint-color-translucent
    --bc    //border-color
    --box-shadow


    **** REQUIRED classes ****
    .float-container
    .d-flex ...

    .toggle-control
    .toggle-option
    .toggle-option-selected

*/
/* Date input field */
.date-input,
.date-input-focus {
    background-color: var(--bgc-input);
    border: 1px solid transparent;
    border-radius: 5px;
    cursor: pointer;
    overflow: hidden;
}
.date-input-focus {
    border-color: var(--tint-color);
    background-color: transparent;
}
.date-input i,
.date-input-focus i {
    color: var(--tint-color);
    font-size: 20px;
    padding: 5px 15px;
    background-color: var(--tint-color-translucent);
}

/* Date/Time picker */
.date-picker {
    position: absolute;
    margin-top: 10px;
    border: 1px solid var(--bc);
    box-shadow: var(--box-shadow);
    overflow: visible;
}
.time-input,
.time-input-focus {
    cursor: pointer;
    border-radius: 5px;
    border: 1px solid transparent;
    background-color: var(--bgc-input);
}
.time-input-focus {
    border-color: var(--tint-color);
    background-color: transparent;
}
.time-input .input-value,
.time-input-focus .input-value {
    padding: 6px 8px;
    width: 50px; text-align: center;
}
.time-picker {
    background-color: var(--bgc);
    width: 64px;
    position: absolute;
    border: 1px solid var(--bc);
    box-shadow: var(--box-shadow);
}
.time-picker .time-picker-option {
    text-align: center;
    display: block;
    cursor: pointer;
}
.time-picker .time-picker-option:hover {
    background-color: var(--tint-color-translucent);
    color: var(--tint-color);
}


/*
@import url('https://db.onlinewebfonts.com/c/86041161c247e3d1d46098d98e60b108?family=VerdanaProW01-Bold');
*/

:root {

    --background-color: #608279; /* old style */
    --background-color: rgb(183, 223, 241);



    --c2-bgc: rgba(161, 205, 234); /* blue pm5 */;

    --c2-bgc: #95ac9e;  /* old style */

    --c2-bgc: #8fc4c0;

    --c2-bc: rgb(54, 54, 56);

    --c2-number-font: Verdana, sans-serif;


    --c2-fc: rgb(30,42,39);
    --c2-fc: rgb(35, 47, 44);

    --c2-fc-dark: rgb(15, 24, 21);
}

.c2screen-results-meta {
    border: 14px solid rgb(54, 54, 56);
    border: 14px solid var(--c2-bc);
    border-color: var(--bc);
    border-radius: 5px;
    overflow: hidden;
    background-color: #8fc4c0;
    background-color: var(--c2-bgc);
    padding: 8px;
}

.c2screen-results-meta h5 {
    font-family: Verdana, sans-serif;
    font-family: var(--c2-number-font);
    color: rgb(35, 47, 44);
    color: var(--c2-fc);
}
.c2screen-results-meta .screen-title {
    color: rgb(15, 24, 21);
    color: var(--c2-fc-dark);
}

.c2screen-results {
    background-color: #8fc4c0;
    background-color: var(--c2-bgc);
    text-align: center;
    border: 2px solid black;
    border-radius: 5px;
    overflow: hidden;
    margin-top: 4px;
}

.c2screen-results .big, .c2screen-results .medium, 
.c2screen-results .small {
    padding: 6px 0px ;
}

.c2screen-results .big {
    font-size: 30px;
    font-weight: 600;
    letter-spacing: 0px;
}

.c2screen-results .medium {
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0px;
}

.c2screen-results .small {
    display: inline;
    font-size: 14px;
    font-weight: 600;
    margin-left: 2px;
    letter-spacing: 0px;
}



.c2screen-meta {
    border: 14px solid var(--c2-bc);
    border-color: var(--bc);
    border-radius: 5px;
    overflow: hidden;
    background-color: var(--c2-bgc);
    padding: 8px;
}

.c2screen-meta h5 {
    font-family: var(--c2-number-font);
    color: var(--c2-fc);
}
.c2screen-meta .screen-title {
    color: var(--c2-fc-dark);
}

.c2screen {
    background-color: var(--c2-bgc);
    text-align: center;
    border: 2px solid black;
    border-radius: 5px;
    overflow: hidden;
    margin-top: 4px;
}

.c2screen .big, .c2screen .medium, 
.c2screen .small {
    padding: 6px 0px ;
}

.c2screen .big {
    font-size: 30px;
    font-weight: 600;
    letter-spacing: 0px;
}

.c2screen .medium {
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0px;
}

.c2screen .small {
    display: inline;
    font-size: 14px;
    font-weight: 600;
    margin-left: 2px;
    letter-spacing: 0px;
}


.c2screen-meta .close-button {
    background-color: var(--c2-bgc);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: black;
    border-radius: 2px;
    border: 1px solid black;
    height: 20px; width: 25px;
}
.c2screen-meta .close-button:hover {
    -webkit-filter: brightness(75%);
            filter: brightness(75%);
}
